import React from "react";
import MailApp from "../pages/apps/mail";
import Page from "@jumbo/shared/Page";
import LAYOUT_NAMES from "../layouts/layouts";
import ProfilePage from "../pages/profile";

const appsRoutes = [

    {
        path: [
            "/app/mails/:category",
            "/app/mails/:category/:id",
            "/app/mails/:category/message/:messageID",
            "/app/mails/:category/:id/message/:messageID"
        ],
        element: <Page component={MailApp}/>
    },
     {
        path: "/user/profile/",
        element: <Page component={ProfilePage}  />
    },
];

export default appsRoutes;
