import React,{lazy} from "react";
import Home from "../pages/home";
import Page from "@jumbo/shared/Page";
import appsRoutes from "./appsRoutes";
import LAYOUT_NAMES from "../layouts/layouts";
import LandingPage from "../pages/home";
import SimplePage from "@jumbo/shared/Page/SimplePage";
const LoginPage = lazy(() => import("../pages/auth/login/Login"));
const HomePage = lazy(() => import("../pages/home/Home"));
const SignupPage = lazy(() => import("../pages/auth/signup/Signup"));
const ForgotPassword = lazy(() => import("../pages/auth/forgot-password"));

 let IMAGE_URL = `url("https://ww2-secure.justanswer.com/static/sip/btc/hero/desktop/tax.jpg")`

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    {
        path: "/home/",
        element: <Page component={Home} />
    },

      {
        path: "/",
        element:  <SimplePage backgroundImage={IMAGE_URL} content={HomePage} />
    },

];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    ...appsRoutes
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
  {
        path: "/auth/forgot-password",
        element: <Page component={ForgotPassword} layout={"solo-page"} />
    },
    {
        path: "/auth/sign-up/",
        element: <Page layout={LAYOUT_NAMES.SOLO_PAGE} component={SignupPage} disableSmLogin />
    },
     {
        path: "/auth/login/",
        element: <Page layout={LAYOUT_NAMES.SOLO_PAGE} component={LoginPage} disableSmLogin />
    },

];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};