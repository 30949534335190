import axios from "./config";
import jwtAuthAxios from "./auth/jwtAuth";
const mailServices = {};

mailServices.getMails = async ({queryKey}) => {
    const {page, limit, queryParams} = queryKey[queryKey.length - 1];
    const {data} = await jwtAuthAxios.get("/mail/", {
        params: {
            page: page,
            limit: limit,
            ...queryParams
        }
    });
    return data;
};


mailServices.getMailDetail = async (id) => {

    const {data} = await jwtAuthAxios.get(`/mail/${id}/`, {});
    return data;
};


mailServices.getConversationMessages = async ({queryKey}) => {
    const {queryParams} = queryKey[queryKey.length - 1];
    const {data} = await jwtAuthAxios.get(`/mail/${queryParams.id}/conversation_messages/`, );
    return data;
}

mailServices.addMail = async (mail) => {
    const {data} = await jwtAuthAxios.post("/mail/", mail);
    return data;
}


mailServices.getLabels = async () => {
    const {data} = await jwtAuthAxios.get("/category/");
    return data;
};

mailServices.addLabel = async (label) => {
    const {data} = await jwtAuthAxios.post('/category/', label);
    return data;
};

mailServices.updateLabel = async (label) => {
    const {data} = await jwtAuthAxios.patch(`/category/${label.id}/`, label);
    return data;
}

mailServices.deleteLabel = async (label) => {
    const {data} = await jwtAuthAxios.delete(`/category/${label.id}/`, {
        params: {}
    });
    return data;
};

mailServices.deleteMultiple = async (selectedIDs) => {
    const {data} = await axios.put("/mails/bulk-delete", {
        mailIDs: selectedIDs
    });
    return data;
};

mailServices.multipleMoveSpam = async (selectedIDs) => {
    const {data} = await axios.put("/mails/bulk-spam", {
        mailIDs: selectedIDs
    });
    return data;
};

mailServices.assignLabel = async (params) => {
    const {data} = await axios.put("/mails/assign-label", {
        mailIDs: params?.mailIDs ?? [],
        labelIDs: params?.labelIDs ?? []
    });
    return data;
};


mailServices.deleteMail = async (mailID) => {
    const {data} = await axios.put("/mail/delete", {
        mailID: mailID
    });
    return data;
};

mailServices.removeArchivedMail = async (mailID) => {
    const {data} = await axios.put('/mail/archived', {
        mailID: mailID
    });
    return data;
};

mailServices.mailMoveToSpam = async (mailID) => {
    const {data} = await axios.put('/mail/report-spam', {
        mailID: mailID
    });
    return data;
};

mailServices.removeFavoriteMail = async (mailID) => {
    const {data} = await axios.put('/mail/favorite', {
        mailID: mailID
    });
    return data;
};

mailServices.setFavorite = async (email) => {
    const {data} = await jwtAuthAxios.post(`/mail/${email.id}/favorite/`, {favorite:email.favorite});
    return data;
};

mailServices.mailConversation = async (email) => {
    const {data} = await jwtAuthAxios.post(`/mail/${email.id}/reply/`, {message:email.message});
    return data;
};


export {mailServices};
