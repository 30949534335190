import React from 'react';
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import {mailServices} from "../../../../../services/mail-services";
import ConversationMessage from "./ConversationMessage";
import {useMutation} from "react-query";
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import useMailsApp from "../../hooks/useMailsApp";
import Div from "@jumbo/shared/Div";
import SendIcon from "@mui/icons-material/Send";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import {useNavigate} from "react-router-dom";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {MAIL_STATUS} from "../../utils/constants";
import {UserType} from "../../../../../utils/constants/main";

const ConversationDetail = ({conversation, authUser}) => {
    console.log("ConversationDetail", conversation)
    const [message, setMessage] = React.useState("");
    const {activeConversation} = useMailsApp();
    const {showDialog, hideDialog} = useJumboDialog();
    const navigate = useNavigate();
    const Swal = useSwalWrapper();

    const renderConversationMessage = React.useCallback((conversation) => {
        return (
            <ConversationMessage reply={conversation} authUser={authUser}/>
        )
    }, []);

    const mailConversationMutation = useMutation(mailServices.mailConversation, {
        onSuccess: () => {
            activeConversation?.current?.refresh();
            setMessage("");
            hideDialog()

             Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Consulta respondida con éxito.',
                showConfirmButton: false,
                timer: 1500
            }).then(value => {
                navigate(`/app/mails/pending`);
             })


        }
    });
    const handleConversationMail = React.useCallback(() => {
        showDialog({
            variant: "confirm",
            title: "Desea enviar esta respuesta?",
            onYes: () =>  {
                mailConversationMutation.mutate({id: conversation.id, message: message})

            },
            onNo: hideDialog
        })
    });


    return (
        <React.Fragment>
            <JumboRqList
                componentElement={'div'}
                ref={activeConversation}
                service={mailServices.getConversationMessages}
                primaryKey={"id"}
                renderItem={renderConversationMessage}
                queryOptions={{
                    queryKey: "reply-thread",
                    dataKey: "messages",
                    queryParams: {id: conversation.id}
                }}
                noDataPlaceholder={<React.Fragment></React.Fragment>}
            />

            {conversation.status === MAIL_STATUS.PENDING && authUser && authUser.user_type === UserType.CONSULTANT &&(
                 <Div
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection:"column"
                }}
            >
                <TextField
                    placeholder={"Enviar respuesta...."}
                    size={"small"}
                    sx={{ flex: 1, }}
                    fullWidth
                    rows={4}
                    multiline={true}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <Button variant={"contained"} disabled={!message || message.length < 20}
                        sx={{mt: 2, width:"200px"}} onClick={handleConversationMail} endIcon={<SendIcon/>}>
                    Enviar
                    {authUser.username}
                </Button>
            </Div>
            )}

        </React.Fragment>
    );
};

export default ConversationDetail;
