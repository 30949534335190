import React from 'react';
import {Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {useMutation, useQuery} from "react-query";
import {mailServices} from "../../../../../services/mail-services";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {makeStyles} from "@mui/styles";
import {Edit} from "@mui/icons-material";
import SendIcon from '@mui/icons-material/Send';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));


const ComposeForm = ({mailItem, onSave, onClose}) => {
     const classes = useStyles();
    const Swal = useSwalWrapper();
    const [to, setTo] = React.useState();
    const [category, setCategory] = React.useState({id:null, name:""});
    const [subject, setSubject] = React.useState();
    const [message, setMessage] = React.useState(mailItem ? mailItem?.message : "");
    const [categories, setCategories] = React.useState([])
      const {
        data,
        isLoading,
        isError,
        error
    } = useQuery(["categories"], () => mailServices.getLabels());


    const addMailMutation = useMutation(mailServices.addMail, {
        onSuccess: () => {
            onSave();
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Consulta enviada con éxito.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    });

    React.useEffect(()=>{
        console.log(data)
        if(data){
        setCategories(data.results)

        }

    },[data])

    const onSendMessage = () => {
        addMailMutation.mutate({category, message});
    }
    return (
        <Box component={'form'}
             sx={{
                 '& > .MuiFormControl-root': {
                     marginBottom: 2
                 }
             }}
              className={classes.root}
        >
            {/*<TextField*/}
            {/*    fullWidth*/}
            {/*    size={"small"}*/}
            {/*    type={"email"}*/}
            {/*    placeholder={"To"}*/}
            {/*    value={to}*/}
            {/*    onChange={(e) => setTo(e.target.value)}*/}
            {/*/>*/}
                <FormControl sx={{width:"100%"}}>
                    <InputLabel  shrink id="demo-simple-select-label">Categoría</InputLabel>
                    <Select

                        size={"small"}
                        fullWidth
                        value={category}
                        label="Categoría"
                        onChange={(event) => setCategory(event.target.value)}
                    >
                        {categories.map((value, index)=> <MenuItem value={value.id}>{value.name}</MenuItem>)}
                    </Select>
                </FormControl>

                {/*<TextField*/}
                {/*    fullWidth*/}
                {/*    size={"small"}*/}
                {/*    placeholder={"Asunto"}*/}
                {/*    value={subject}*/}
                {/*    onChange={(e) => setSubject(e.target.value)}*/}
                {/*/>*/}
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    maxRows={6}
                    placeholder={"Mensaje"}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", width:"100%", my:2}}>
                <Button  color={"error"} startIcon={<DoNotDisturbIcon/>} variant={"contained"} onClick={onClose}>Cancelar</Button>
                <Button endIcon={<SendIcon/>} variant={"contained"} onClick={onSendMessage}>Enviar</Button>
            </Box>

        </Box>
    );
};
/* Todo mailItem, onSave prop define */
export default ComposeForm;
