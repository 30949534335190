import React from 'react';
import {Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Chip from "@mui/material/Chip";
import moment from "moment/moment";
import {UserType} from "../../../../../utils/constants/main";

const ConversationMessage = ({reply, authUser}) => {
    return (
        <React.Fragment>

            <Div
                sx={{
                    position: 'relative',
                    textAlign: 'center',
                    mb: 2,
                    '&:after': {
                        display: 'inline-block',
                        content: "''",
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        height: '1px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: 'divider',
                    },
                }}
            >
                <Typography variant={"h2"} mb={2}>
                    Respuesta
                    {authUser && authUser.user_type !== UserType.CUSTOMER && (
                        ' de  '+ reply.sender.username
                    )}
                </Typography>

                <Chip
                    label={moment(reply?.timestamp).format("DD MMMM, HH:MM")+"hs"}
                    variant="outlined"
                    sx={{
                        position: 'relative',
                        zIndex: 1,
                        bgcolor: theme => theme.palette.background.paper,
                        borderColor: 'divider',
                        borderRadius: 2
                    }}
                />
            </Div>
            <Typography mb={2}>{reply.message}</Typography>
        </React.Fragment>
    );
};

export default ConversationMessage;
