import React from 'react';
import useJumboApp from "../../../@jumbo/hooks/useJumboApp";
import LAYOUT_NAMES, {LAYOUTS} from "../../layouts/layouts";
import useJumboLayoutSidebar from "../../../@jumbo/hooks/useJumboLayoutSidebar";
import {SIDEBAR_VARIANTS} from "@jumbo/utils/constants";
import useJumboLayoutHeader from "../../../@jumbo/hooks/useJumboLayoutHeader";
import {useJumboLayoutContent, useJumboLayoutFooter, useJumboLayoutRoot} from "@jumbo/hooks";
import {Box, List, ListItem, Typography} from "@mui/material";
import styled from "@emotion/styled";
import CircleIcon from '@mui/icons-material/Circle';
import Link from "@mui/material/Link";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import {useNavigate} from "react-router-dom";
import LoginIcon from '@mui/icons-material/Login';
import {getAssetPath} from "../../utils/appHelpers";
import {ASSET_IMAGES} from "../../utils/constants/paths";
import KeyboardIcon from '@mui/icons-material/Keyboard';

import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

const Div = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
}));
let IMAGE_URL = `url("https://ww2-secure.justanswer.com/static/sip/btc/hero/desktop/tax.jpg")`
// let IMAGE_URL =`url("${getAssetPath(`${ASSET_IMAGES}/background2.jpg`, "420x380")}") `
// IMAGE_URL = \`url(https://img.freepik.com/foto-gratis/business-brainstorming-graph-chart-informe-datos-concepto_53876-31213.jpg?w=740&t=st=1690233826~exp=1690234426~hmac=da40a8ac4121595dac0b5793f9e2932fe94f7014cacaa50865db993e4eea79b6)\`

const HomePage = () => {
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {headerOptions, setHeaderOptions} = useJumboLayoutHeader();
    const {setFooterOptions} = useJumboLayoutFooter();
    const {setContentOptions} = useJumboLayoutContent();
    const {setRootOptions} = useJumboLayoutRoot();
    const navigate = useNavigate();

    React.useState(() => {
        setSidebarOptions({variant: SIDEBAR_VARIANTS.TEMPORARY, open:false});
        setHeaderOptions({hide: true});
        setFooterOptions({hide: true});

        setContentOptions({sx:{
                p:0,
                m:0,
            }})
        //
        // setRootOptions({sx:{
        //         p:0,
        //         pl:0
        //     }})
    }, []);

    const goToSignUp = ()=>{
        const url = "/auth/sign-up/"
        navigate(url)
    }

    const goToLogin = ()=>{
        const url = "/auth/login/"
        navigate(url)
    }

    const StepsItem = ({title, content, icon})=>{
        return (
            <Box sx={{display:"flex", flexDirection:"row", alignItems:"flex-start"}}>
                <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                    {icon}
                </Box>
                <Box>
                     <Typography variant={"h3"} sx={{color:"black", fontSize:35}}>
                        {title}
                    </Typography>
                     <Typography variant={"h4"} sx={{color:"black", fontSize:25, maxWidth:"600px", fontWeight:"300", textAlign:"justify"}}>
                        {content}
                    </Typography>
                </Box>

            </Box>


        )
    }

    return (
        <Box  >
            <Box sx={{display:"flex", flexDirection:"row-reverse"}}>
                <Button variant="contained"
                        onClick={()=>{
                            goToLogin()
                        }}
                        endIcon={<LoginIcon />}
                        color={"info"}
                        sx={{position:"absolute", maxWidth:"300px", mt:4, borderRadius:50,mr:4}}>
                    Ingresar
                </Button>
            </Box>
            <Box sx={styles.section1}>
                <Box sx={{display:"flex", flexDirection:"column",  height:"90vh", justifyContent:"flex-start" }}>
                    <Box>
                        <Typography variant="h1" gutterBottom sx={{fontWeight:"500", color:"black", mt:10, fontSize:35}}>CONSULTAS TRIBUTARIAS</Typography>
                        <Typography variant={"h3"} sx={{color:"black", fontSize:25}}>
                            Ayuda de Contabilidad en Línea
                        </Typography>
                    </Box>
                    <Button variant="contained"
                            onClick={()=>{
                                goToSignUp()
                            }}
                            endIcon={<SubscriptionsIcon />} sx={{maxWidth:"300px", mt:4, borderRadius:50}}>
                        Subscribite
                    </Button>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", ml:"10%", width:"40%", height:"90vh",
                    justifyContent:"space-between", alignItems:"flex-end"}}>
                    <Box>
                        <Typography variant={"h3"} sx={{color:"black", marginTop: "auto", fontSize:35,  mt:12}}>
                            Realiza tus consultas con nuestros expertos y obten respuestas en minutos.
                        </Typography>

                        <List
                            sx = {{
                                listStyleType: 'disc',
                                pl: 2,
                                '& .MuiListItem-root': {
                                    display: 'list-item',
                                },
                                color:"black"
                            }}>
                            <ListItem>
                                <Typography variant={"h3"} sx={{color:"black", fontWeight:"400"}}>
                                    Impuestos sobre la nómina, ayuda con la presentación, auditorías, impuestos estatales, impuestos federales y más.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant={"h3"} sx={{color:"black", fontWeight:"400"}}>
                                    Ayuda con impuestos comerciales, formularios del IRS, auditorías, impuestos estatales e impuestos federales
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant={"h3"} sx={{color:"black", fontWeight:"400"}}>
                                    Planes que se ajustan a tus necesidades
                                </Typography>
                            </ListItem>
                        </List>

                    </Box>

                </Box>
            </Box>
            <Box sx={styles.whatsappContainer}>
                <Link href="https://api.whatsapp.com/send?phone=51955081075&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202." variant="body2">
                    <WhatsAppIcon sx={{color:"white", fontSize:"35px", textAlign:"center", verticalAlign:"center"}}/>
                </Link>
            </Box>

            <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:4}}>
                <Typography variant="h1" gutterBottom sx={{fontWeight:"500", color:"black", mt:10, fontSize:35, mb:4}}>CÓMO FUNCIONA?</Typography>

                <StepsItem
                    title="Realice su consulta"
                    content={"Escriba lo que está pasando para que el sistema pueda encontrar al mejor experto en contabilidad para su situación."}
                    icon={  <KeyboardIcon sx={{fontSize:80, mr:3, mt:1}} />}
                />

                <StepsItem
                    title="Conecta"
                    content={"Escriba lo que está pasando para que el sistema pueda encontrar al mejor experto en contabilidad para su situación."}
                    icon={  <ConnectWithoutContactIcon sx={{fontSize:80, mr:3, mt:1}} />}
                />

            </Box>
            <Box sx={{px:6, mt:20, py:2}}>

            </Box>
        </Box>
    );


};

const styles = {

    section1:{
        height:"100vh",
        display:"flex",
        flexDirection:{
            sm:"row", lg:"row", md:"row"
        },
        justifyContent:"space-between",
        px:6,
        width:"100%",
        backgroundSize: "cover",
        backgroundImage:IMAGE_URL
    },
    whatsappContainer:{
        position:"fixed",
        width:"60px",
        display:"flex",
        height:"60px",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center",
        ml:"auto",
        right:"40px",
        bottom:"40px",
        backgroundColor:"#25d366",
        color:"white",
        borderRadius:"50px",
        textAlign:"center",
        fontSize:"30px",
        boxShadow:"2px 2px 3px #999",
        zIndex:100
    }
}

export default HomePage;