import React from "react";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PeopleIcon from '@mui/icons-material/People';

const menus = [


    {
        label: 'CONSULTAS',
        type: "section",
        children: [

            {
                uri: "/app/mails/pending",
                label: 'sidebar.menuItem.mail',
                type: "nav-item",
                icon: <EmailOutlinedIcon sx={{fontSize: 20}}/>
            },

            // {
            //     uri: "/user/",
            //     label: 'Usuarios',
            //     type: "nav-item",
            //     icon: <PeopleIcon sx={{fontSize: 20}}/>
            // }
        ]
    },

];

export default menus;
