import jwtAuthAxios from "./auth/jwtAuth";

const authServices = {};

authServices.getCurrentUser = async () => {
    const {data} = await jwtAuthAxios.get("/current-user/");
    return data;
};

//loginCreds must be {email: "abc@example.com", password: "ABC123DEF"}
authServices.signIn = async (loginCreds) => {
    const data = await jwtAuthAxios.post('/token/', loginCreds);
    return data;
};

authServices.signUp = async (user) => {
    const data= await jwtAuthAxios.post('/sign-up/', user);
    return data;
}

authServices.resetPassword = async (email) => {
    const data = await jwtAuthAxios.post('/forgot-password/', {
        email:email
    });
    return data;
};


export default authServices;