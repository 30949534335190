import React from 'react';
import useJumboApp from "@jumbo/hooks/useJumboApp";
import {config} from "../../../app/config/main";
import {SIDEBAR_VARIANTS} from "@jumbo/utils/constants";
import useJumboLayoutSidebar from "../../hooks/useJumboLayoutSidebar";
import useJumboLayoutHeader from "../../hooks/useJumboLayoutHeader";
import {useJumboLayoutContent, useJumboLayoutFooter, useJumboLayoutRoot} from "@jumbo/hooks";

const SimplePage = ({content, backgroundImage}) => {
    const {setSidebarOptions} = useJumboLayoutSidebar();
    const { setHeaderOptions} = useJumboLayoutHeader();
    const {setFooterOptions} = useJumboLayoutFooter();
    const {setContentOptions} = useJumboLayoutContent();
    const {setRootOptions} = useJumboLayoutRoot();

    React.useState(() => {
        setSidebarOptions({variant: SIDEBAR_VARIANTS.TEMPORARY, open:false});
        setHeaderOptions({hide: true});
        setFooterOptions({hide: true});
        const contentOpts = {
                p:0,
                paddingLeft:0,
                pl:0,
                m:0,
                width:"100%",
                height:"auto",
                backgroundSize: "cover"
            }

        if(backgroundImage){
            contentOpts["backgroundImage"] = backgroundImage
        }
        setContentOptions({sx: contentOpts})

        setRootOptions({sx:{
                p:0,
                pl:0
            }})
    }, []);


    const SimpleComponent = content

    return <SimpleComponent />
};



export default SimplePage;