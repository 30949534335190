import React from 'react';
import LabelsList from "../LabelsList";
import Button from "@mui/material/Button";
import {Edit} from "@mui/icons-material";
import FoldersList from "../FoldersList";
import FiltersList from "../FiltersList";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ComposeForm from "../ComposeForm";
import useMailsApp from "../../hooks/useMailsApp";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {UserType} from "../../../../../utils/constants/main";

const MailAppSidebar = ({authUser}) => {
    const {hideDialog, showDialog} = useJumboDialog();
    const {setMailsListRefresh} = useMailsApp();

    const handleMailSent = React.useCallback(() => {
        hideDialog();
        setMailsListRefresh(true);
    }, [hideDialog, setMailsListRefresh]);


    const handleComposeForm = React.useCallback(() => {
        showDialog({
            title: "Redactar Consulta",
            content: <ComposeForm onSave={handleMailSent} onClose={()=>hideDialog()}/>,
            PaperProps:{
                sx:{
                    width:"700px",
                    // height:"600px",
                },
            },
            disableDefaultClose:true,
            maxWidth: false,
            sx:{
                height: "700px"
            }
        })
    }, [showDialog]);
    return (
        <React.Fragment>
            {authUser && authUser.user_type === UserType.CUSTOMER && (
                <Button
                    fullWidth
                    disableElevation
                    variant={"contained"}
                    startIcon={<Edit/>}
                    sx={{
                        mb: 4,
                        '& .MuiSvgIcon-root': {
                            fontSize: '1.5rem'
                        }
                    }}
                    onClick={handleComposeForm}
                >Consulta</Button>

            )}

            <FoldersList/>
            <FiltersList/>
            <LabelsList authUser={authUser}/>
        </React.Fragment>
    );
};

export default MailAppSidebar;
