import React from 'react';
import {List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import authServices from "../../services/auth-services";
import useJumboLayout from "../../../@jumbo/hooks/useJumboLayout";
import layoutConfig from "../../layouts/solo-page/layoutConfig";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";


const validationSchema = yup.object({
    username: yup
        .string('Nombre de usuario')
        .required('usuario requerido'),
    password: yup
        .string('Contraseña')
        .required('Contraseña requerida'),
});

const ProfilePage = ({disableSmLogin}) => {
    const {setAuthToken, authUser} = useJumboAuth();
    const navigate = useNavigate();



    const {setJumboLayoutOptions} = useJumboLayout();

    React.useEffect(() => {
        console.log("AUTHUSER", authUser)
    }, []);


    const onSignIn = (username, password) => {
        authServices.signIn({username, password})
            .then((data) => {
                console.log("ok", data)
                setAuthToken(data?.access);
                navigate("/app/mails/pending");
            });

    };




        return (
            <div>
                <JumboCardQuick title={"Mis Datos"} noWrapper>
                    <List disablePadding sx={{mb: 2}}>
                        <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                            <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                                <EmailOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="body1" color="text.secondary">Email</Typography>}
                                secondary={<Link variant="body1" href="#" underline="none">kiley.brown@example.com</Link>}
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                            <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                                <InsertLinkOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="body1" color="text.secondary">Web page</Typography>}
                                secondary={<Link variant="body1" href="#" underline="none">example.com</Link>}
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(.5, 3)}}>
                            <ListItemIcon sx={{minWidth: 36, color: 'text.secondary'}}>
                                <LocalPhoneOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="body1" color="text.secondary">Phone</Typography>}
                                secondary={<Typography variant="body1" color="text.primary">+1-987 (454) 987</Typography>}
                            />
                        </ListItem>
                    </List>
                </JumboCardQuick>
            </div>
        );


};

export default ProfilePage;
